let wakeLock = null;

export function requestWakeLock() {
    try {
        navigator.wakeLock.request('screen')
            .then(wl => {
                wakeLock = wl;
                wakeLock.addEventListener('release', () => {
                    console.log('Wake Lock was released');
                });
                console.log('Wake Lock is active');
            });
    } catch (err) {
        console.log('Unable to request wake-lock: ' + err.name + ', ' + err.message);
    }
}

export function releaseWakeLock() {
    if (!wakeLock) {
        return;
    }
    try {
        wakeLock.release();
        wakeLock = null;
    } catch (err) {
        console.log('Unable to release wake-lock: ' + err.name + ', ' + err.message);
    }
}
