import 'bootstrap';
import '@fancyapps/fancybox';
import '../scss/morsmad.scss';

import {requestWakeLock} from "./wakelock.js";

$(document).ready(() => {
    const recipeId = $("meta[name='recipe-id']").attr('content');
    if(recipeId !== undefined) {
        requestWakeLock();
    }
});

$('.collapse').on('show.bs.collapse', (e) => {
    $('.collapse').filter(el => el.id != e.target.id).collapse('hide');
});

$('#content').mouseover(() => {
    $('.collapse').collapse('hide');
});

$('[data-fancybox="gallery"]').fancybox({
    infobar: false,
    loop: true,
    buttons: ["close"]
});

$('#workmodeModal').on('show.bs.modal', (e) => {
    try {
        let elem = document.getElementById('workmodeModal');
        elem.requestFullscreen();
        if(screen.orientation && screen.orientation.lock) {
            screen.orientation.lock("landscape-primary")
                .catch(err => console.log('Could not lock screen orientation: ', err));
        }
    } catch (err) {
        console.log(err);
    }
//    requestWakeLock();
}).on('hide.bs.modal', (e) => {
    try {
        if(screen.orientation && screen.orientation.unlock) {
            screen.orientation.unlock();
        }
        document.exitFullscreen();
    } catch (err) {
        console.log(err);
    }
//    releaseWakeLock();
});

$('.recipetags')
    .on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
    });
$('[data-toggle="tooltip"]:parent')
    .on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
    });
$('[data-toggle="tooltip"]').tooltip();
